<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";

import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";


import { alertData } from "./intervention";

export default {
    setup() {
    return { v$: useVuelidate() };
  },
    page: {
        title: "Paramétrage des Types d'interventions",
    },
    components: {
        Layout,
        PageHeader
    },
    data() {
        return {
            alertData: alertData,
            title: "Paramétrage des Niveaux d'alerte",
            items: [
                {
                    text: "Paramétrage",
                    href: "/settings",
                },
                {
                    text: "Types d'interventions",
                    active: true,
                },
            ],
            showModal: false,
      submitted: false,
      params: {
        libelle: "",
      },
        };
    },
    validations: {
    params: {
      libelle: {
        required: helpers.withMessage("Libelle is required", required),
      },
    },
  },
  methods: {
    /**
     * Modal form submit
     */
    // eslint-disable-next-line no-unused-vars
    handleSubmit() {
      this.submitted = true;

      // stop here if form is invalid
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      } else {
        this.showModal = false;
        this.params = {};
      }
      this.submitted = false;
    },
  },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12 d-none d-lg-block">
                <button class="btn btn-primary float-end" @click="showModal = true">
                    <i class="bx bx-plus-circle align-middle me-1"></i> Ajouter
                </button>
            </div>
        </div>
    <b-modal v-model="showModal" title="Ajouter un type d'intervention" hide-footer>
      <form @submit.prevent="handleSubmit">
        <div class="row">
          <div class="col-12">
            <div class="mb-3">
              <label for="name">Libellé du type d'intervention</label>
              <input
                id="libelle"
                v-model="params.libelle"
                type="text"
                class="form-control"
                placeholder="Donnez le nom du niveau"
                :class="{
                  'is-invalid': submitted && v$.params.libelle.$error,
                }"
              />
               <div
                v-if="submitted && v$.params.libelle.$error"
                class="invalid-feedback"
              >
                <span v-if="v$.params.libelle.required.$message">{{
                  v$.params.libelle.required.$message
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="text-end mt-3">
          <b-button variant="light" @click="showModal = false">Fermer</b-button>
          <b-button type="submit" variant="success" class="ms-1"
            >Créer</b-button
          >
        </div>
      </form>
    </b-modal>
        <div class="row">
            <div class="col-lg-12">
                <div>
                    <div class="table-responsive">
                        <table
                            class="table project-list-table table-nowrap table-centered table-borderless align-middle">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th scope="col">Libellé des interventions</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="treatment in alertData" :key="treatment.id">
                                    <td>
                                        <img :src="`${treatment.image}`" alt class="avatar-sm" />
                                    </td>
                                    <td>
                                        <h3 class="text-truncate font-size-18">
                                            <a href="javascript: void(0);" class="text-dark">{{
                                                    treatment.text
                                            }}</a>
                                        </h3>
                                    </td>
                                    <td>
                                        <router-link to="" class="action-icon text-success me-2">
                                            <i class="mdi mdi-eye font-size-18"></i>
                                        </router-link>
                                        <router-link to="" class="action-icon text-primary me-2">
                                            <i class="mdi mdi-pencil font-size-18"></i>
                                        </router-link>
                                        <router-link to="" class="action-icon text-danger">
                                            <i class="mdi mdi-trash-can font-size-18"></i>
                                        </router-link>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <!-- end row -->

    </Layout>
</template>
