const alertData = [
    {
        id: 1,
        image: require('@/assets/images/small/sos.jpg'),
        text: 'Intervention Braquage',
    },
    {
        id: 2,
        image: require('@/assets/images/small/sos.jpg'),
        text: 'Intervention Vol à mains armées',
    },
    {
        id: 3,
        image: require('@/assets/images/small/sos.jpg'),
        text: "Intervention d'arrestation",
    },
    {
        id: 4,
        image: require('@/assets/images/small/sos.jpg'),
        text: "Intervention d'enquête",
    }
];

export { alertData };